import Utils from "../common/utils";
import SystemConstant from "./SystemConstant";
import {FileInfo} from "../common/define";


export default class StreamApi{
	private static host = `${SystemConstant.protocol}://${SystemConstant.Stream.host}`;
	public static getUrlFile(file: FileInfo) {
		return `${StreamApi.host}/${
			SystemConstant.Stream.API.DownloadFile}?${
			Utils.parseUrl({
				path: `${file.streamLocation}/${file.filename}`,
			}).toString()}`;
	}

}
