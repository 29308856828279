import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FoxitState } from "common/define-foxit";
import PdfHelper from "helper/pdf.helper";
import { getPdfViewer } from "hooks/useCreatePdfViewer";
import { concatMap, filter } from "rxjs/operators";
import { BaseFileInfo } from "../common/define";
import { escapeDetail } from "./detailPage.slice";
import { setCurrentOperator } from "./pdfViewer.slice";
import { RootEpic } from "./rootReducers";

interface AppSlice {
    baseFileInfo: BaseFileInfo | null;
    loading: boolean;
    fileName: string;
    excelLoading: boolean;
}

const initState: AppSlice = {
    baseFileInfo: null,
    loading: true,
    fileName: '',
    excelLoading: false,
};

const appSlice = createSlice({
    name: 'app',
    initialState: initState,
    reducers: {
        updateBaseFileInfo(state, action: PayloadAction<BaseFileInfo>) {
            state.baseFileInfo = action.payload;
            state.loading = false;
        },
        setLoadingApp(state, action: PayloadAction<boolean>) {
            state.loading = action.payload;
        },
        updateFileName(state, action: PayloadAction<string>) {
            state.fileName = action.payload
        },
        setExcelLoading(state, action: PayloadAction<boolean>) {
            state.excelLoading = action.payload;
        },
        escapeAll() { }
    }
});

export const escapeAll$: RootEpic = action$ => action$.pipe(
    filter(escapeAll.match),
    concatMap(_ => {
        const pdfViewer = getPdfViewer();
        if (pdfViewer) {
            PdfHelper.drawMode(FoxitState.hand, pdfViewer);
            PdfHelper.removeCurrentAnnot();
            return [
                setCurrentOperator('pan'),
                escapeDetail()
            ]
        }
        return []
    })
)
export const {
    updateBaseFileInfo,
    setLoadingApp,
    updateFileName,
    escapeAll,
    setExcelLoading } = appSlice.actions;
export default appSlice.reducer;
