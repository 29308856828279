import { Point } from "common/define";
import { FoxitState } from "common/define-foxit";
import { getPdfViewer } from "hooks/useCreatePdfViewer";
import { mapAnnot } from "hooks/useHandleEventPdf";
export const pdfInitTemplate = [
    '<webpdf>',
    ' <toolbar>',
    ' <tabs hidden>',
    ' <tab>',
    ' <div class="flex" style="display: flex;">',
    ' <create-textbox-button></create-textbox-button>',
    ' <create-text-highlight-button></create-text-highlight-button>',
    ' <create-callout-button></create-callout-button>',
    ' <create-pencil-button></create-pencil-button>',
    ' <create-attachment-button></create-attachment-button>',
    ' <create-strikeout-button></create-strikeout-button>',
    ' <create-underline-button></create-underline-button>',
    ' <create-squiggly-button></create-squiggly-button>',
    ' <create-replace-button></create-replace-button>',
    ' <create-caret-button></create-caret-button>',
    ' <create-note-button></create-note-button>',
    ' <create-drawings-dropdown></create-drawings-dropdown>',
    ' <create-area-highlight-button></create-area-highlight-button>',
    ' <hand-button></hand-button>',
    ' <marquee-tool-button></marquee-tool-button>',
    ' <selection-button></selection-button>',
    ' </div>',
    ' </tab>',
    ' </tabs>',
    ' </toolbar>',
    ` <tooltip-layer name="fv--text-selection-tooltip" class="fv--ui-text-selection-tooltip">
          <xbutton @controller="text-sel:CopySelectedTextController" icon-class="fv__icon-popup-copy"></xbutton>
      </tooltip-layer>`,
    ' <viewer></viewer>',
    '</webpdf>',
];

export const pdfInitAddOns = [
    `${process.env.PUBLIC_URL}/foxit-core/uix-addons/undo-redo/`,
];

const minMaxDeltaScale = {
    min: 0.1,
    max: 4,
    delta: 0.1
};
export default class PdfHelper {
    static async zoomToExc(pdfViewer: any, nextScale: any, location: { x: number, y: number } | null = null) {
        let p = 0; let d = 0; let h = 0;
        const pdfDocRender = await pdfViewer.getPDFDocRender();
        const viewRender = pdfDocRender.viewerRender.$ui.parent()[0] as HTMLElement;
        const bounding = viewRender.getBoundingClientRect();
        const { left, top, width, height } = bounding;
        const clientX = location?.x || width / 2 + left;
        const clientY = location?.y || height / 2 + top;
        let w;
        let _;
        let j = await pdfDocRender.pagesRender.viewMode.getVisibleIndexes() || [0];
        let allowZoom = false;
        for (let i = 0; i < j.length; i++) {
            p = j[i];
            let a = await pdfDocRender.getPDFPageRender(p).$ui.get(0).getBoundingClientRect();
            if (clientX <= a.right && clientX >= a.left && clientY >= a.top && clientY <= a.bottom) {
                w = left - clientX;
                d = clientX - a.left;
                if (d < 0) d = 0;
                _ = top - clientY;
                h = clientY - a.top;
                if (h < 0) h = 0;
                allowZoom = true;
                break;
            }
        }
        let S = pdfDocRender.getPDFPageRender(p);
        let D = S.page.reverseDevicePoint([d, h], S.scale, S.getRotation());
        let T = S.page.reverseDeviceOffset([w, _], nextScale, S.getRotation());
        const params = {
            pageIndex: p,
            x: D[0] + T[0],
            y: D[1] + T[1],
        };
        allowZoom && pdfViewer.zoomTo(nextScale, params).catch((() => {
        }));
    }
    static zoomInOut(pdfViewer: any, currentScale: number, type: 'in' | 'out', location: { x: number, y: number } | null = null) {
        let scale: number;
        if (type === 'in') {
            const tempScaleIn = currentScale + minMaxDeltaScale.delta;
            scale = tempScaleIn > minMaxDeltaScale.max ? minMaxDeltaScale.max : tempScaleIn;
        } else {
            const tempScaleIn = currentScale - minMaxDeltaScale.delta;
            scale = tempScaleIn < minMaxDeltaScale.min ? minMaxDeltaScale.min : tempScaleIn;
        }
        PdfHelper.zoomToExc(pdfViewer, scale);
    }
    static goToPage(pdfViewer: any, pageIndex: number) {
        pdfViewer.getPDFDocRender().goToPage(pageIndex, { x: 0, y: 0 }, 0, true);
    }
    static navigatorPage(currentPageIndex: number, type: 'pre' | 'next'): number {
        let page = -1;
        if (type === 'pre') {
            page = currentPageIndex - 1;
        } else if (type === 'next') {
            page = currentPageIndex + 1;
        }
        return page;
    }
    static currentPage(pdfViewer: any): number {
        return pdfViewer.getPDFDocRender().getCurrentPageIndex();
    }
    static async getPdfPage(pdfViewer: any, pageIndex: number) {
        const pdfPage = await pdfViewer.getPDFPageRender(pageIndex)?.getPDFPage();
        return pdfPage;
    }


    static async rotatePage(pdfViewer: any) {
        try {
            //All rotation pages
            let pageCount = pdfViewer.customRenderManager.pdfViewer.currentPDFDoc.info.pageCount;
            const pageIndex = PdfHelper.currentPage(pdfViewer);
            for (let index = 0; index < pageCount; index++) {
                const pdfPage = await PdfHelper.getPdfPage(pdfViewer, index);
                const curPageRotation = pdfPage.getRotation();
                const nextRotation = curPageRotation > 3 ? 0 : curPageRotation + 1
                await pdfPage.setRotation(nextRotation);
            }
            const pdfPage = await PdfHelper.getPdfPage(pdfViewer, pageIndex);
            const curPageRotation = pdfPage.getRotation();
            return curPageRotation
            //Single rotation page
            // const pageIndex = PdfHelper.currentPage(pdfViewer);
            // console.log("🚀 ~ pageIndex", pageIndex)
            // const pdfPage = await PdfHelper.getPdfPage(pdfViewer, pageIndex);
            // console.log("🚀 ~ pdfPage", pdfPage)
            // const curPageRotation = pdfPage.getRotation();
            // const nextRotation = (curPageRotation + 1) % 4;
            // pdfPage.setRotation(nextRotation);
        } catch (error) {
            console.log(error);
        }

    }

    static getAnnotBoundary(page: any, annotBound: any, rotation: 0 | 90 | 180 | 270) {
        return page.getDeviceRect(annotBound, 1, rotation);
    }

    static getAnnotDevicePoint(page: any, annotPoint: [number, number], rotation: 0 | 90 | 180 | 270): Point {
        const res = page.getDevicePoint(annotPoint, 1, rotation);
        return { x: res[0], y: res[1] };
    }

    static getAnnotDevicePoints(page: any, annotPoints: Point[], rotation: 0 | 90 | 180 | 270): Point[] {
        return annotPoints?.map((p) => PdfHelper.getAnnotDevicePoint(page, [p.x, p.y], rotation));
    }


    static async goHomePage(pdfViewer: any) {
        const { offsetWidth: curElementWidth, offsetHeight: curElementHeight } = pdfViewer.$ui[0].parentElement;
        const pageIndex = PdfHelper.currentPage(pdfViewer);
        const page = await PdfHelper.getPdfPage(pdfViewer, PdfHelper.currentPage(pdfViewer));
        const width = page.getWidth();
        const height = page.getHeight();
        if (width / curElementWidth > height / curElementHeight) {
            await pdfViewer.zoomTo('fitWidth')
        } else {
            await pdfViewer.zoomTo('fitHeight')
        }
        pdfViewer.getPDFDocRender().goToPage(pageIndex, { x: 0, y: 0 }, 0, true);
    }
    static pageHandler(pdfViewer: any) {
        const pageRender = pdfViewer.getPDFPageRender(PdfHelper.currentPage(pdfViewer));
        return pageRender?.$handler;
    }
    static drawMode(mode: FoxitState, pdfViewer: any = null) {
        const pdfViewerFinal = pdfViewer ?? getPdfViewer();
        const stateManager = pdfViewerFinal.getStateHandlerManager();
        stateManager && stateManager.switchTo(mode);
    }

    static SelectMode(mode: FoxitState, pdfViewer: any = null) {
        const pdfViewerFinal = pdfViewer ?? getPdfViewer();
        const stateManager = pdfViewerFinal.getStateHandlerManager();
        stateManager && stateManager.switchTo(mode);
    }
    static async removeAnnot(annot: any) {
        const pdfViewer = getPdfViewer();
        if (pdfViewer) {
            const pageIndex = pdfViewer.getPDFDocRender().getCurrentPageIndex();
            const pdfPage = await pdfViewer.getPDFPageRender(pageIndex)?.getPDFPage().then((res: any) => {
                if (res) {
                    try {
                        res.removeAnnotById(annot.id)
                    }
                    catch (err) { }
                }
            });
        }
    }
    static async removeAnnotByID(annotId: any) {
        const pdfViewer = getPdfViewer();
        if (pdfViewer) {
            const pageIndex = pdfViewer.getPDFDocRender().getCurrentPageIndex();
            const pdfPage = await pdfViewer.getPDFPageRender(pageIndex)?.getPDFPage().then((res: any) => {
                if (res) {
                    try {
                        res.removeAnnotById(annotId)
                    }
                    catch (err) { }
                }
            });
        }
    }
    static async removeAllSingleHighlight() {
        const pdfViewer = getPdfViewer();
        if (pdfViewer) {
            const pageIndex = pdfViewer.getPDFDocRender().getCurrentPageIndex();
            const pdfPage = await pdfViewer.getPDFPageRender(pageIndex)?.getPDFPage().then((res: any) => {
                if (res) {
                    res.annots.forEach((val: any) => {
                        if (val.info.type === 'highlight' && val.info.title === 'SingleHighlight') {
                            try {
                                res.removeAnnotById(val.info.id)
                            }
                            catch (err) { }
                        }
                    })
                }
            });
        }
    }
    static removeCurrentAnnot() {
        const annot = mapAnnot.get('annot');
        if (annot) {
            PdfHelper.removeAnnot(annot);
        }
    }

    //thumbnail
    public static canvas2Image(canvasData: any) {
        const { buffer, width, height } = canvasData;
        const canvas = document.createElement('canvas') as HTMLCanvasElement;
        canvas.setAttribute('style', 'display: none;');
        if (canvas) {
            const ctx = canvas.getContext('2d');
            if (ctx) {
                ctx.canvas.width = width;
                ctx.canvas.height = height;
                const imgData = ctx.createImageData(width, height);
                const arrayBuffer = new (Uint8ClampedArray || Uint8Array)(buffer);
                imgData.data.set(arrayBuffer);
                ctx.putImageData(imgData, 0, 0);
            }

        }
        return canvas.toDataURL();
    }
    public static async getThumbnail(pageIndex: number) {
        const pdfPage = (getPdfViewer().currentPDFDoc.pageCache as any[])[pageIndex];
        if (pdfPage) {
            const thumb = await pdfPage.getThumb();
            const imgUrl: string = PdfHelper.canvas2Image(thumb);
            return imgUrl;
        }
    }
}


