/* eslint-disable @typescript-eslint/no-explicit-any */
import { DetailInfoPage, ExcludeReviewField, LocationScan, PageReviewShortcut } from "./define";
import { DataColumnResult, TextObject } from "./define-TX-Table";

class Utils {
    static setLocalStorage(key: string, value: unknown): void {
        localStorage.setItem(key, JSON.stringify(value));
    }
    static getValueLocalStorage(key: string): any | null {
        const value = localStorage.getItem(key);
        let re = null;
        value && (re = Utils.parseJson(value));
        return re;
    }
    static removeItemLocalStorage(key: string): void {
        localStorage.removeItem(key);
    }
    static parseJson(str: string): any | null {
        try {
            return JSON.parse(str);
        } catch (e) {
            return null;
        }
    }

    static getFileNameWithoutExtension(fileOrigin: string | undefined): string {
        return fileOrigin?.substring(0, fileOrigin.lastIndexOf('.')) ?? '';
    }
    static parseUrl(obj: { [key: string]: string }): URLSearchParams {
        const params = new URLSearchParams();
        Object.keys(obj).forEach((key) => {
            const value = obj[key];
            if (key && value) {
                params.set(key, value)
            }
        })
        return params;
    }

    static getFirstItem<T>(list: T[], condition: (item: T) => boolean): { item: T, index: number } | null {
        let re: { item: T, index: number } | null = null;
        list.some((item, index) => {
            if (condition(item)) {
                re = { item, index };
                return true;
            }
            return false;
        });
        return re;
    }
    static isRectangleEnoughLarge(x1: number, y1: number, x2: number, y2: number): boolean {
        return (Math.abs(x2 - x1) >= 2 && Math.abs(y2 - y1) >= 2);
    }
    static getTitle(str: string): string {
        const regex = /(^.)(\w+)([A-Z]\w+)/;
        const match = str.match(regex);
        if (match && match.length > 2) {
            return `${match[1].toUpperCase()}${match[2]} ${match[3]}`;
        }
        const uppercaseFirstChar = str.charAt(0).toUpperCase() + str.slice(1);
        return uppercaseFirstChar;
    }
    static uppercaseFirstChar(str: string): string {
        return str && (str.charAt(0)?.toUpperCase() + str.slice(1));
    }
    static checkMatched(data: Omit<PageReviewShortcut, 'pageIndex'>): boolean {
        const arrFields = Object.keys(data);
        if (arrFields && arrFields.length > 0) {
            const noMatched = arrFields.some(f => {
                const key = f as ExcludeReviewField;
                const field = data[key];
                if (!field || field.priority === undefined || field.priority !== 1) {
                    return true;
                }
                return false;
            });
            return !noMatched;
        }
        return false;
    }
    static checkMatchByPageDetail(data: DetailInfoPage): boolean {
        const { pageIndex, ...newData } = data;
        const arrFields = Object.keys(newData);
        if (arrFields && arrFields.length > 0) {
            const noMatched = arrFields.some(f => {
                const key = f as ExcludeReviewField;
                const field = data[key];
                if (!field || field.priority === undefined || field.priority !== 1) {
                    return true;
                }
                return false;
            });
            return !noMatched;
        }
        return false;
    }
    static mapDataCompleteVerify(data: DetailInfoPage) {
        const { pageIndex, ...fields } = data;
        let result = { pageIndex };
        Object.keys(fields).forEach(f => {
            const key = f as ExcludeReviewField;
            let valueTemp = fields[key];
            if (!valueTemp || !valueTemp.boundary) {

                const newBounday: LocationScan = {
                    width: 0, height: 0, x: 0, y: 0
                };
                valueTemp = {
                    value: '',
                    boundary: newBounday,
                    priority: 1
                }
            }
            valueTemp = { ...valueTemp, ...{ priority: 1 } };
            result = { ...result, ...{ [key]: valueTemp } };
        });
        return result as DetailInfoPage;
    }

    public static newGuid() {
        return 'xxxxxxxx-xxxx-xxxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
            const r = Math.random() * 16 | 0;
            // eslint-disable-next-line no-mixed-operators
            const v = c === 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }
    static initData(data: any[]) {
        if (data && data.length > 0) {
            let lenght = data[0]?.textObjects?.length;
            let needInitData = false;
            data.forEach(item => {
                const sub = item.textObjects.length - lenght;
                if (sub > 0) {
                    lenght = item.textObjects.length;
                }
                if (sub !== 0) {
                    needInitData = true;
                }
            });
            if (lenght && needInitData) {
                data.forEach(item => {
                    const sub = lenght - item.textObjects.length;
                    if (sub > 0) {
                        for (let i = 0; i < sub; i++) {
                            item.textObjects.push({});
                        }
                    }
                })
            }
        }
        return data;
    };

    static cloneObject(obj: any) {
        return JSON.parse(JSON.stringify(obj));
    };

    static mergeArray = (updatePageCurrentData: DataColumnResult, updateData: DataColumnResult, expand: boolean = false) => {
        if (updatePageCurrentData && updateData) {
            let result: any[] = [];
            updateData.columnItems?.forEach((item: any) => {
                let find = updatePageCurrentData?.columnItems?.find((y: any) => item.columnName === y.columnName);
                if (find && expand) {
                    let data: TextObject[] = Utils.cloneObject(find.textObjects);
                    for (let i = data ? data.length - 1 : 0; i >= 0; i--) {
                        if (!data[i] || (!data[i].text && !data[i].rect)) data.pop();
                        else break;
                    }
                    result.push({
                        ...item,
                        textObjects: data.concat(item.textObjects)
                    });
                } else {
                    find = result.find(z => z.columnName === item.columnName);
                    if (!find) {
                        result.push(item);
                    }
                }
            });
            const expandArr = updatePageCurrentData?.columnItems?.filter((x: any) => !result.find(z => z.columnName === x.columnName));
            if (expandArr && expandArr.length > 0) {
                result = result.concat(expandArr);
            }

            if (result.length > 0) {
                result = Utils.initData(result);
                updateData.columnItems = result;
            }
            updateData.serialName = updatePageCurrentData.serialName;
        }
        return updateData;
    };
}

export default Utils;
