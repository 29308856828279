import Utils from 'common/utils';
import { throwError } from 'rxjs';
import { ajax, AjaxRequest, AjaxResponse } from 'rxjs/ajax';
import { Observable } from 'rxjs/internal/Observable';
import {catchError, map, retry} from 'rxjs/operators';

function mapResponse(res: AjaxResponse) {
    if (res.response) {
        return res.response;
    }
}

function handleError$(err: unknown): Observable<unknown> {
    console.log(err);
    return throwError(err);
}

function createHeaders(headers?: AjaxRequest) {
    const token = Utils.getValueLocalStorage('token');
    const newHeaders = {
        Authorization: token ? `Bearer ${token}` : '',
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        ...headers
    };
    return newHeaders;
}

export default class Http {
    static get(url: string, headers?: AjaxRequest): Observable<unknown> {
        const newHeaders = createHeaders(headers);
        return ajax({url, method: 'GET', ...newHeaders}).pipe(
            map((res: AjaxResponse) => mapResponse(res)),
            retry(2),
            catchError((err) => handleError$(err))
        );
    }

    static post(url: string, data: unknown, headers?: AjaxRequest): Observable<unknown> {
        const newHeaders = createHeaders(headers);
        return ajax({url, method: 'POST', body: data, headers: {...newHeaders}}).pipe(
            map((res) => mapResponse(res)),
            retry(2),
            catchError((err) => handleError$(err))
        )
    }
}