import {
    BaseFileInfo,
    DataApplySheet,
    DetailInfoPage,
    LocationScan,
    ResponseFileInfo,
    ScanColumnPolyline
} from "common/define";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import Http from "./http.api";
import SystemConstant from "./SystemConstant";
import {DataColumnResult, ScanColumn} from "../common/define-TX-Table";
export default class TextExtractionApi {
    private static host = `${SystemConstant.protocol}://${SystemConstant.TextExtraction.host}`;
    // static getOverViewFile(baseFileId: string): Observable<PageReviewShortcut[]>{
    //     const api = SystemConstant.TextExtraction.API.PostResultPreview;
    //     return Http.get(`${TextExtractionApi.host}/${api}/${baseFileId}`).pipe(map((re: any) => re as PageReviewShortcut[]));
    // }
    static getOverViewFile(baseFileId: string): Observable<any>{
        const api = SystemConstant.TextExtraction.API.GetNumberAnalysisPage;
        return Http.get(`${TextExtractionApi.host}/${api}/${baseFileId}`).pipe(map((re: any) => re as any));
    }
    static getDetailInfoPage(baseFileId: string | undefined, pageIndex: number): Observable<DetailInfoPage> {
        const api = SystemConstant.TextExtraction.API.ColumnResultsEachPage;
        return Http.get(`${TextExtractionApi.host}/${api}/${baseFileId}/${pageIndex}`).pipe(map((re: any) => re as DetailInfoPage));
    }
    // static getDetailInfoPage(baseFileId: string | undefined, pageIndex: number): Observable<DetailInfoPage> {
    //     const api = SystemConstant.TextExtraction.API.ResultPreviewPage;
    //     return Http.get(`${TextExtractionApi.host}/${api}/${baseFileId}/${pageIndex}`).pipe(map((re: any) => re as DetailInfoPage));
    // }
    static scanFieldValue(baseFileId: string, pageIndex: number, location: LocationScan): Observable<string> {
        const api = SystemConstant.TextExtraction.API.SearchCurrentSheet(pageIndex);
        const dataRequest: any = {
            location,
            baseFileId
        };
        return Http.post(`${TextExtractionApi.host}/${api}`, dataRequest).pipe(map(re => re as string))
    }

    static scanTableValue(dataRequest: ScanColumn): Observable<DataColumnResult> {
        const api = SystemConstant.TextExtraction.API.ScanColumn(dataRequest?.pageIndex);
        return Http.post(`${TextExtractionApi.host}/${api}`, dataRequest).pipe(map(re => re as DataColumnResult))
    }
    static scanPolylineTableValue(dataRequest: ScanColumnPolyline): Observable<DataColumnResult> {
        const api = SystemConstant.TextExtraction.API.ScanColumnPolyline(dataRequest?.pageIndex);
        return Http.post(`${TextExtractionApi.host}/${api}`, dataRequest).pipe(map(re => re as DataColumnResult))
    }
    static applyFieldCurrentSheet(pageIndex: number, data: DataApplySheet): Observable<string> {
        const api = SystemConstant.TextExtraction.API.applyField(pageIndex);
        return Http.post(`${TextExtractionApi.host}/${api}`, data).pipe(map(re => re as string))
    }
    static applyFieldAllSheet(data: DataApplySheet): Observable<string> {
        const api = SystemConstant.TextExtraction.API.applyAllSheet;
        return Http.post(`${TextExtractionApi.host}/${api}`, data).pipe(map(re => re as string))
    }
    static getFileInfo(baseFileId: string) {
        const api = SystemConstant.TextExtraction.API.getFileInfo(baseFileId);
        const uri = `${TextExtractionApi.host}/${api}`;
        return Http.get(uri).pipe(map(re => re as ResponseFileInfo))
    }
    static analytic(data: BaseFileInfo) {
        const api = SystemConstant.TextExtraction.API.analytics;
        return Http.post(`${TextExtractionApi.host}/${api}`, data).pipe(map(re => re as string))
    }
    static downloadFileExcel(baseFileId: string) {
        const api = SystemConstant.TextExtraction.API.downloadExcel(baseFileId);
        const uri = `${TextExtractionApi.host}/${api}`;
        return uri;
    }
    static downloadExcelNCAnalysis(baseFileId: string) {
        const api = SystemConstant.TextExtraction.API.downloadExcelNCAnalysis(baseFileId);
        return `${TextExtractionApi.host}/${api}`;
    }
    static completeVerification(baseFileId: string, data: DetailInfoPage) {
        const api = SystemConstant.TextExtraction.API.completeVerification(baseFileId);
        const uri = `${TextExtractionApi.host}/${api}`;
        return Http.post(uri, data)
    }
    static searchText(baseFileId: string, searchText: string){
        const api = SystemConstant.TextExtraction.API.SearchText(baseFileId, searchText);
        const uri = `${TextExtractionApi.host}/${api}`;
        return Http.get(uri);
    }
    static postApproveScanColumnResult(pageNumber:any, data:any) {
        const api = SystemConstant.TextExtraction.API.ApproveScanColumnResult(pageNumber);
        const uri = `${TextExtractionApi.host}/${api}`;
        return Http.post(uri, data)
    }
}
