import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import { I18nextProvider } from "react-i18next";
import i18n from "i18next";
import { initLanguage } from './helper/managerLanguage';

initLanguage();

ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <App />
  </I18nextProvider>,
  document.getElementById('root')
);

