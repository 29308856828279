/* eslint-disable react-hooks/exhaustive-deps */
import { BoundaryScan, StatePdfCreate } from "common/define";
import { FoxitEvents } from "common/define-foxit";
import PdfHelper from "helper/pdf.helper";
import { useEffect } from "react";
import { AnyAction } from "redux";
import { setIsScaningTable, fetchScanTable, PayloadScanTable, setListHighlight, setInitialExtractRotation } from "redux/detailPage.slice";
import { globalStatePdfViewer, setStateWhenRenderSuccess } from "redux/pdfViewer.slice";
import { useDispatchRoot, useSelectorRoot } from "redux/store";
import { activeSelect } from 'redux/detailPage.slice';
import { setCurrentOperator } from 'redux/pdfViewer.slice';
import { FoxitState } from 'common/define-foxit';
export enum PDFAnnotFlag {
    Invisible = 1,
    Hidden = 2,
    Print = 4,
    NoZoom = 8,
    NoRotate = 16,
    NoView = 32,
    ReadOnly = 64,
    Locked = 128,
    ToggleNoView = 256,
    LockedContents = 512
}
type HandleEventObject = [FoxitEvents, Function[] | Function];
function registerHandleEvent(pdfViewer: any, mapEvent: HandleEventObject[]) {
    if (pdfViewer) {
        mapEvent.forEach(arr => {
            const [nameEvent, arrHandle] = arr;
            if (Array.isArray(arrHandle)) {
                arrHandle.forEach(event => {
                    pdfViewer.getEventEmitter().on(nameEvent, event);
                })
            } else {
                pdfViewer.getEventEmitter().on(nameEvent, arrHandle);
            }
        })
    }
}

function handleRenderFileSuccess(pdfViewer: any, dispatch: (event: AnyAction) => void) {
    const curPage: number = PdfHelper.currentPage(pdfViewer);
    const currentScale = pdfViewer.getPDFPageRender(curPage).scale;
    dispatch(setStateWhenRenderSuccess(currentScale));
    // console.log('render success');
}

function handleZoomToSuccess(pdfViewer: any) {
    const curPage: number = PdfHelper.currentPage(pdfViewer);
    const currentScale = pdfViewer.getPDFPageRender(curPage).scale;
    globalStatePdfViewer.currentPdfScale = currentScale;
}

function wheelRequest(wheelEvent: React.WheelEvent, pdfViewer: any) {
    const currentScale = globalStatePdfViewer.currentPdfScale;
    const { clientX: x, clientY: y } = wheelEvent;
    if (wheelEvent.deltaY >= 1) {
        PdfHelper.zoomInOut(pdfViewer, currentScale, 'out', { x, y })
    } else if (wheelEvent.deltaY <= -1) {
        PdfHelper.zoomInOut(pdfViewer, currentScale, 'in', { x, y })
    }
}

function addAnnotSuccess(annot: any, _pdfViewer: any, dispatch: (event: AnyAction) => void) {
    // remove all prev annot
    PdfHelper.removeCurrentAnnot();

    // make annot unable to select
    annot.setFlags(PDFAnnotFlag.ReadOnly, null);

    //set to pan mode after creating an annot
    dispatch(setCurrentOperator('pan'));
    dispatch(activeSelect(false));
    PdfHelper.drawMode(FoxitState.hand);

    dispatch(setIsScaningTable(true));
    dispatch(setListHighlight([]));
    if (annot.page.info.rotation) {
        dispatch(setInitialExtractRotation(annot.page.info.rotation));
    }
    mapAnnot.set('annot', annot);
    setTimeout(async () => {
        const boundaryScan: BoundaryScan = annot.info.rect;
        const deviceBound = PdfHelper.getAnnotBoundary(annot.page, boundaryScan, 0);
        const deviceDevicePoints = PdfHelper.getAnnotDevicePoints(annot.page, annot.info?.vertexes, 0);
        const { left, top, bottom, right } = deviceBound;
        const locationConvert: PayloadScanTable = {
            width: Math.abs(right - left),
            height: Math.abs(top - bottom),
            x: left,
            y: top,
            pageHeight: annot.page._pxHeight,
            pageWidth: annot.page._pxWdith,
            polylines: deviceDevicePoints
        };
        // dispatch(fetchScanField(locationConvert))
        dispatch(fetchScanTable(locationConvert))
    }, 0);
}
function unActiveAnnotPDF(annotRender: any, pdfViewer: any) {
    const { annotsRender } = pdfViewer.getPDFPageRender(annotRender.annot.page.info.index);
    annotsRender.unActiveAnnot();
}
export async function getCurrentPdfPage() {
    if (pdfData) {
        const pdfPage = await PdfHelper.getPdfPage(pdfData.pdfViewer, PdfHelper.currentPage(pdfData.pdfViewer));
        return pdfPage;
    }
    return new Promise((_resolve, _reject) => { });
}
export async function getCurrentPdfViewer() {
    if (pdfData) {
        return pdfData.pdfViewer;
    }
    return new Promise((_resolve, _reject) => { });
}
export async function getPdfViewerByPageIndex(pageIndex: number) {
    if (pdfData) {
        const pdfPage = await PdfHelper.getPdfPage(pdfData.pdfViewer, pageIndex);
        return pdfPage;
    }
}
export async function getSingleThumbnail(pageIndex: number) {
    const pdfPage = await getPdfViewerByPageIndex(pageIndex);
    if (pdfPage) {
        const thumb = await pdfPage.getThumb();
        const imgUrl: string = PdfHelper.canvas2Image(thumb);
        return imgUrl;
    }
}
export const mapAnnot = new Map<'annot', any>();
let pdfData: any = null;
export default function useHandleEventPdf(pdfCreated: StatePdfCreate) {
    pdfData = pdfCreated;
    const dispatch = useDispatchRoot();
    // const { rotation } = useSelectorRoot<any>(state => state.actionInfo);
    const handleEvent: HandleEventObject[] = [
        ['render-file-success', () => handleRenderFileSuccess(pdfCreated.pdfViewer, dispatch)],
        ['zoom-to-success', () => handleZoomToSuccess(pdfCreated.pdfViewer)],
        ['wheel-request', (wheelEvent: React.WheelEvent) => wheelRequest(wheelEvent, pdfCreated.pdfViewer)],
        ['add-annot-success', (annot: any) => addAnnotSuccess(annot, pdfCreated.pdfViewer, dispatch)],
        ['active-annotation', (annotRender: any) => unActiveAnnotPDF(annotRender, pdfCreated.pdfViewer)]
    ];

    useEffect(() => {
        if (pdfCreated.pdfViewer) {
            registerHandleEvent(pdfCreated.pdfViewer, handleEvent);
        }
    }, [pdfCreated.pdfViewer])
}
