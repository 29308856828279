import React from 'react';
import { createStyles, ThemeDefine } from 'theme/Theme';
import useStyleTheme from 'hooks/useStyleTheme';
import { Props } from 'common/define';
import clsx from 'clsx';

type SizeLoading = 'default' | 'small' | 'large';
interface PropsLoading extends Props {
    size?: SizeLoading;
    speed?: number;
    noOpacityBackground?: boolean,
    visible?: boolean;
    fullScreen?: boolean;
}

const sizeElementLoading: Record<SizeLoading, string> = {
    default: '14px',
    small: '12px',
    large: '16px'
};

const rootStyles = createStyles((theme: ThemeDefine) => ({
    rootContainer: {
        position: 'relative',
        width: '100%',
        height: '100%'
    },
    rootLoading: {
        display: 'flex',
        zIndex: theme.zIndex.loading,
        textAlign: 'center',
        pointerEvents: 'auto',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: (props: PropsLoading) => props.noOpacityBackground ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, .8)',
        '& > div': {
            width: (props: PropsLoading) => sizeElementLoading[props.size!],
            height: (props: PropsLoading) => sizeElementLoading[props.size!],
            borderRadius: '100%',
            display: 'inline-block',
            backgroundColor: theme.palette.primary,
            animationFillMode: 'both',
            animationName: '$bouncedelay',
            animationDuration: (props: PropsLoading) => `${props.speed}s`,
            animationTimingFunction: 'ease-in-out',
            animationIterationCount: 'infinite',
            margin: theme.spacing(0, 0.5)
        }
    },
    '@keyframes bouncedelay': {
        '0%, 80%, 100%': {
            transform: 'scale(0)'
        },
        '40%': {
            transform: 'scale(1)'
        }
    },
    animationDelay1: {
        animationDelay: '-0.48s'
    },
    animationDelay2: {
        animationDelay: '-0.32s'
    },
    animationDelay3: {
        animationDelay: '-0.16s'
    },
    position: {
        margin: 'auto',
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0
    },
    full: {
        width: '100vw',
        height: '100vh'
    }
}));

const LoadingComponent = (
    {
        size = 'default', 
        speed = 1.4, 
        noOpacityBackground = false,
        visible = false,
        fullScreen = false,
        children, ...props
    }: PropsLoading
): JSX.Element | null => {
    const classes = useStyleTheme(rootStyles, {size, speed, noOpacityBackground});

    if (!children && !visible) {
        return null;
    }
    return (
        <section className={clsx(
            classes.rootContainer,
            props.className,
            {
                [classes.full]: fullScreen === true
            }
        )}>
            {children}
            {
                visible &&
                <div className={`${classes.rootLoading} ${classes.position}`}>
                    <div className={classes.animationDelay1}></div>
                    <div className={classes.animationDelay2}></div>
                    <div className={classes.animationDelay3}></div>
                </div>
            }
        </section>
    )
}

const CLoading = React.memo(LoadingComponent);
export default CLoading;