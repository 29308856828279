import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import TextExtractionApi from "api/textExtraction.api";
import { DataApplySheet, fieldDetailPage, LocationScan, TypeApply, ValueOfFieldDetailPage } from "common/define"
import Utils from "common/utils";
import PdfHelper from "helper/pdf.helper";
import { catchError, concatMap, filter, switchMap, withLatestFrom } from "rxjs/operators";
import { setPageCurrentData } from "./actionInfo.slice";
import { escapeAll, setLoadingApp } from "./app.slice";
import { RootEpic } from "./rootReducers";
import { DataColumnResult, HighlightData } from "../common/define-TX-Table";

interface ActionApply {
    value: any;
    type: TypeApply;
}
interface DetailPageState {
    isSelect: boolean;
    isSubmit: boolean;
    modeDraw: string;
    loadingRotation: boolean;
    annotPage: any;
    isFoldOutlined: boolean;
    visibleConfirmDialog: boolean;
    fieldFocus: fieldDetailPage | null;
    fieldScanMatched: fieldDetailPage | null;
    boundaryScan: LocationScan | null;
    isScaning: boolean;
    isScaningTable: boolean;
    resultScan: string;
    resultScanTable: DataColumnResult | null;
    listHighlight: HighlightData[];
    focusItem: any;
    initialExtractRotation: number;
    currentLanguage: string;
}

export interface PayloadScanTable extends LocationScan {
    pageWidth: number;
    pageHeight: number;
    polylines: any
}

const initState: DetailPageState = {
    isSelect: false,
    isSubmit: false,
    annotPage: "",
    modeDraw: "Square",
    loadingRotation: false,
    isFoldOutlined: false,
    visibleConfirmDialog: false,
    fieldFocus: null,
    fieldScanMatched: null,
    boundaryScan: null,
    isScaning: false,
    isScaningTable: false,
    resultScan: '',
    resultScanTable: null,
    listHighlight: [],
    focusItem: null,
    initialExtractRotation: 0,
    currentLanguage: "en"
}

const detailPageSlice = createSlice({
    name: 'detailPage',
    initialState: initState,
    reducers: {
        activeSelect(state, action) {
            state.isSelect = action.payload;
        },
        setCurrentLanguage(state, action) {
            state.currentLanguage = action.payload;
        },
        setLoadingRotation(state, action) {
            state.loadingRotation = action.payload;
        },
        setAnnotPage(state, action) {
            state.annotPage = action.payload;
        },
        setSubmit(state, action) {
            state.isSubmit = action.payload;
        },
        setModeDraw(state, action) {
            state.modeDraw = action.payload;
        },
        setFoldOutlined(state, action) {
            state.isFoldOutlined = action.payload;
        },
        fetchScanField(state, action: PayloadAction<LocationScan>) {
            state.visibleConfirmDialog = true;
            state.boundaryScan = action.payload;
            state.resultScan = ''
        },
        fetchScanTable(state, action: PayloadAction<PayloadScanTable>) {
            state.visibleConfirmDialog = true;
            state.boundaryScan = action.payload;
            state.resultScanTable = null;
        },
        setVisibleConfirmDialog(state, action: PayloadAction<boolean>) {
            state.visibleConfirmDialog = action.payload
        },
        setfieldScanMatched(state, action: PayloadAction<fieldDetailPage>) {
            state.fieldScanMatched = action.payload
        },
        setIsScaning(state, action: PayloadAction<boolean>) {
            state.isScaning = action.payload;
        },
        setIsScaningTable(state, action: PayloadAction<boolean>) {
            state.isScaningTable = action.payload;
        },
        updateResultScan(state, action: PayloadAction<string>) {
            state.resultScan = action.payload;
        },
        updateResultScanTable(state, action: PayloadAction<DataColumnResult>) {
            state.resultScanTable = action.payload;
        },
        apply(state, action: PayloadAction<ActionApply>) { },
        escapeDetail(state) {
            state.fieldFocus = null;
            state.fieldScanMatched = null;
            state.isScaning = false;
        },
        error(state) {
            state.resultScan = '';
            state.isScaning = false;
            state.isScaningTable = false;
            state.fieldScanMatched = null;
            state.boundaryScan = null;
            state.visibleConfirmDialog = false;
            PdfHelper.removeCurrentAnnot();
        },
        setListHighlight(state, action: PayloadAction<HighlightData[]>) {
            const prv = state.listHighlight.filter(val => {
                return !action.payload.some(item => item.rowValue === val.rowValue && item.columnValue === val.columnValue && item.id === val.id);
            });
            state.listHighlight = [...prv, ...action.payload];
        },
        setListHighlightAfterDeleteRow(state, action: PayloadAction<HighlightData[]>) {
            state.listHighlight = action.payload;
        },
        removeHighlightByRow(state, action: PayloadAction<number>) {
            const filteredList = state.listHighlight.filter(val => val.rowValue !== action.payload);
            state.listHighlight = [...filteredList];
        },
        removeHighlightByID(state, action: PayloadAction<string>) {
            const filteredList = state.listHighlight.filter(val => val.id !== action.payload);
            state.listHighlight = [...filteredList];
        },
        setNewListHighlight(state, action: PayloadAction<HighlightData[]>) {
            state.listHighlight = action.payload;
        },
        setFocusItem(state, action: PayloadAction<any>) {
            state.focusItem = action.payload;
        },
        setInitialExtractRotation(state, action: PayloadAction<number>) {
            state.initialExtractRotation = action.payload;
        },
    }
})

export const fetchScanField$: RootEpic = (action$, state$) => action$.pipe(
    filter(fetchScanField.match),
    withLatestFrom(state$),
    switchMap(([creator, state]) => {
        const locationScan = creator.payload;
        const baseFileId = state.app.baseFileInfo?.baseFileId;
        const pageCurrentIndex = state.actionInfo.pageCurrentIndex;
        if (baseFileId && pageCurrentIndex >= 0) {
            return TextExtractionApi.scanFieldValue(baseFileId, pageCurrentIndex, locationScan).pipe(
                concatMap(re => [detailPageSlice.actions.updateResultScan(re), setIsScaning(false)]),
                catchError(err => [
                    detailPageSlice.actions.error(),
                    escapeAll(),
                ])
            )
        }
        return [detailPageSlice.actions.error()];
    })
);

export const fetchScanTable$: RootEpic = (action$, state$) => action$.pipe(
    filter(fetchScanTable.match),
    withLatestFrom(state$),
    switchMap(([creator, state]) => {
        const location: any = creator.payload;
        const { pageWidth, pageHeight, polylines } = location
        const baseFileInfo = state.app.baseFileInfo;
        const pageIndex = state.actionInfo.pageCurrentIndex;
        const rotation = state.actionInfo.rotation;
        const modeDraw = state.detailPage.modeDraw;
        if (baseFileInfo?.baseFileId && pageIndex >= 0) {
            if (modeDraw === "Square") {
                return TextExtractionApi.scanTableValue(
                    {
                        ...baseFileInfo,
                        location,
                        rotation,
                        pageIndex
                    }
                ).pipe(
                    concatMap((re) => [detailPageSlice.actions.updateResultScanTable(re), setIsScaningTable(false)]),
                    catchError(err => [
                        detailPageSlice.actions.error(),
                        escapeAll(),
                        detailPageSlice.actions.activeSelect(false)
                    ])
                )
            }
            else if (modeDraw === "Polygon") {
                return TextExtractionApi.scanPolylineTableValue(
                    {
                        ...baseFileInfo,
                        rotation,
                        pageWidth,
                        pageHeight,
                        polylines,
                        pageIndex
                    }).pipe(
                        concatMap((re) => [detailPageSlice.actions.updateResultScanTable(re), setIsScaningTable(false)]),
                        catchError(err => [
                            detailPageSlice.actions.error(),
                            escapeAll(),
                            detailPageSlice.actions.activeSelect(false)
                        ])
                    )
            }
        }
        return [detailPageSlice.actions.error()];
    })
);

export const apply$: RootEpic = (action$, state$) => action$.pipe(
    filter(apply.match),
    withLatestFrom(state$),
    switchMap(([creator, state]) => {
        const { value, type } = creator.payload;
        const { boundaryScan, fieldScanMatched } = state.detailPage;
        const { baseFileInfo } = state.app;
        const { pageCurrentIndex, pageCurrentData } = state.actionInfo;
        const bodyResquest: DataApplySheet = {
            location: boundaryScan!,
            baseFileId: baseFileInfo!.baseFileId,
            fieldName: Utils.uppercaseFirstChar(fieldScanMatched!),
            updateValue: value
        };
        const api$ = type === 'applySheet' ?
            TextExtractionApi.applyFieldCurrentSheet(pageCurrentIndex, bodyResquest) :
            TextExtractionApi.applyFieldAllSheet(bodyResquest);
        return api$.pipe(
            concatMap(re => {
                const newFieldValue: { [key: string]: ValueOfFieldDetailPage } = {
                    [fieldScanMatched!]: {
                        value: value,
                        boundary: boundaryScan!,
                        priority: 1
                    }
                };
                const newPageData = { ...pageCurrentData!, ...newFieldValue };
                return [
                    setLoadingApp(false),
                    setPageCurrentData(newPageData),
                    escapeAll()
                ]
            }),
            catchError(err => [
                escapeAll(),
                detailPageSlice.actions.error()
            ])
        )
    })
)

export const {
    activeSelect,
    updateResultScanTable,
    setSubmit,
    setCurrentLanguage,
    setAnnotPage,
    setLoadingRotation,
    setModeDraw,
    setFoldOutlined,
    setVisibleConfirmDialog,
    setfieldScanMatched,
    fetchScanField,
    fetchScanTable,
    setIsScaning,
    setIsScaningTable,
    apply,
    escapeDetail,
    setListHighlight,
    setListHighlightAfterDeleteRow,
    removeHighlightByRow,
    removeHighlightByID,
    setNewListHighlight,
    setFocusItem,
    setInitialExtractRotation,
} = detailPageSlice.actions;
export default detailPageSlice.reducer;
