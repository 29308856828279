import { useTheme } from "react-jss";
import { ThemeDefine } from "theme/Theme";

export default function useStyleTheme<T extends string, K extends string>(
    useStyles: (data?: unknown) => Record<T, string>, 
    props?: Record<K, unknown>
): Record<T, string> {
    const theme = useTheme<ThemeDefine>();
    return useStyles({...props, theme});
}
