import { AnyAction, combineReducers } from "@reduxjs/toolkit";
import { combineEpics, Epic } from "redux-observable";
import actionInfoReducer, { completeVerification$, fetchDetailInfoPage$, fetchOverView$ } from "./actionInfo.slice";
import pdfViewerReducer from "./pdfViewer.slice";
import appReducer, { escapeAll$ } from "./app.slice";
import detailPageReducer, { apply$, fetchScanField$, fetchScanTable$ } from "./detailPage.slice";

const rootReducer = combineReducers({
    pdfViewer: pdfViewerReducer,
    actionInfo: actionInfoReducer,
    app: appReducer,
    detailPage: detailPageReducer
});

export const rootEpic = combineEpics(
    fetchOverView$,
    fetchDetailInfoPage$,
    fetchScanField$,
    fetchScanTable$,
    apply$,
    escapeAll$,
    completeVerification$,
);

export type RootState = ReturnType<typeof rootReducer>;
export type RootEpic = Epic<AnyAction, AnyAction, RootState>;
export default rootReducer;
