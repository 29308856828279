import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import TextExtractionApi from "api/textExtraction.api";
import { BaseFileInfo, DetailInfoPage, ModeAction } from "common/define";
import Utils from "common/utils";
import { catchError, filter, map, mergeMap, switchMap, withLatestFrom } from "rxjs/operators";
import { escapeAll } from "./app.slice";
import { RootEpic } from "./rootReducers";
import { ColumnItem, DataColumnResult } from "../common/define-TX-Table";
import SystemConstant from "api/SystemConstant";

interface ActionInfoState {
    // listOverView: PageReviewShortcut[];
    loading: boolean;
    countPage: number;
    rotation: number;
    modeAction: ModeAction;
    pageCurrentIndex: number;
    pageCurrentData: DataColumnResult | null;
    isPageCurrentMatched: boolean;
    thumbnailSrc: any[];
}

const initState: ActionInfoState = {
    // listOverView: [],
    loading: false,
    countPage: 0,
    rotation: 0,
    modeAction: 'pages',
    pageCurrentIndex: 0,
    pageCurrentData: null,
    isPageCurrentMatched: false,
    thumbnailSrc: [],
};

const actionInfoSlice = createSlice({
    name: 'actionInfo',
    initialState: initState,
    reducers: {
        fetchOverView(state, action: PayloadAction<BaseFileInfo>) {
            state.loading = true;
        },
        setCountPage(state, action: any) {
            state.countPage = action.payload;
        },
        setRotate(state, action: PayloadAction<number>) {
            state.rotation = action.payload;
        },
        // updateListOverView(state, action: PayloadAction<PageReviewShortcut[]>) {
        //     state.listOverView = action.payload;
        //     state.loading = false;
        // },
        updateListOverView(state) {
            state.loading = false;
        },
        switchMode(state, action: PayloadAction<ModeAction>) {
            state.modeAction = action.payload
        },
        jumpPage(state, action: PayloadAction<number>) {
            state.loading = true;
            state.pageCurrentIndex = action.payload;
            state.modeAction = 'detail'
        },
        justJumpPage(state, action: PayloadAction<number>) {
            state.pageCurrentIndex = action.payload;
        },
        setPageCurrentIndex(state, action: PayloadAction<number>) {
            state.pageCurrentIndex = action.payload;
        },
        setThumbnailSrc(state, action: PayloadAction<any>) {
            if (!state.thumbnailSrc.some(val => val.pageNumber === action.payload.pageNumber)) {
                state.thumbnailSrc.push(action.payload);
                state.thumbnailSrc = [...new Set(state.thumbnailSrc)];
            }
        },
        setPageCurrentData(state, action: PayloadAction<DetailInfoPage | null | undefined | DataColumnResult>) {
            const data: any = action.payload;
            state.pageCurrentData = data;
            state.loading = false;
            if (data) {
                state.isPageCurrentMatched = Utils.checkMatchByPageDetail(data);
            } else {
                state.isPageCurrentMatched = false;
            }
        },
        setIsMatchedPageCurrent(state, action: PayloadAction<boolean>) {
            state.isPageCurrentMatched = action.payload
        },
        completeVerification(state, action: PayloadAction<DetailInfoPage>) {
            state.loading = true;
        },
        completeVerificationSuccess(state) {
            state.loading = false;
        },
        err(state, action: PayloadAction<string>) {
            state.loading = false;
        },
        setPageTableName(state, action: PayloadAction<DataColumnResult>) {
            state.pageCurrentData = action.payload;
        },
    }
});

export const fetchOverView$: RootEpic = action$ => action$.pipe(
    filter(fetchOverView.match),
    switchMap((data) => {
        return TextExtractionApi.getOverViewFile(data.payload.baseFileId).pipe(
            mergeMap(result => {
                //Format data listOverView
                let newPayload: any = []
                for (let index = 0; index < result.length; index++) {
                    // const element = result[index];
                    newPayload = [...newPayload, { pageIndex: 1 }];
                }
                return [updateListOverView(), escapeAll()]
            }),
            catchError(err => [actionInfoSlice.actions.err('err fetch overview file')])
        )
    })
);

export const fetchDetailInfoPage$: RootEpic = (action$, state$) => action$.pipe(
    filter(jumpPage.match),
    withLatestFrom(state$),
    switchMap(([creator, state]) => {
        const pageNum = creator.payload;
        return TextExtractionApi.getDetailInfoPage(state.app.baseFileInfo?.baseFileId, pageNum).pipe(
            mergeMap((result: any) => {
                // Sort ColumnItem
                if (result) {
                    let newValueTable: any = [];
                    try {
                        SystemConstant.NameTable.forEach(element => {
                            if (result) {
                                let newData: ColumnItem[] = Array.from(result.columnItems);
                                if (newData.filter((word) => word.columnName === element).length > 0) {
                                    const dataColumn = newData.filter((word) => word.columnName === element);
                                    newValueTable.push(dataColumn[0])
                                } else {
                                    newValueTable.push({
                                        textObjects: [],
                                        columnName: element,
                                        columnType: -1,
                                    })
                                }
                            }
                        });
                    } catch (error) {
                        console.log(error);
                    }
                    return [setPageCurrentData({ columnItems: newValueTable, serialName: result?.serialName }), escapeAll()]
                } else {
                    // Fake DataColumnResult 
                    // If result === undefined
                    const dataDemo: DataColumnResult =
                    {
                        columnItems:
                            SystemConstant.NameTable.map(
                                (name) =>
                                ({
                                    textObjects: [],
                                    columnName: name,
                                    columnType: -1
                                })
                            ),
                        serialName: ""
                    };
                    return [setPageCurrentData(dataDemo), escapeAll()]
                }

            }),
            catchError(err => [setPageCurrentData(null), actionInfoSlice.actions.err('err fetch detail page')])
        )
    })
);
export const justJumpPage$: RootEpic = action$ => action$.pipe(
    filter(justJumpPage.match),
    switchMap((_) => [escapeAll()])
);
export const completeVerification$: RootEpic = (action$, state$) => action$.pipe(
    filter(completeVerification.match),
    withLatestFrom(state$),
    switchMap(([creator, state]) => {
        const data = creator.payload;
        const baseFileId = state.app.baseFileInfo?.baseFileId;
        const mapData = Utils.mapDataCompleteVerify(data);
        return TextExtractionApi.completeVerification(baseFileId!, mapData).pipe(
            map(re => actionInfoSlice.actions.completeVerificationSuccess()),
            catchError(err => [actionInfoSlice.actions.err('err action complete verify')])
        )
    })
);

export const {
    fetchOverView,
    setRotate,
    updateListOverView,
    switchMode,
    jumpPage,
    justJumpPage,
    setPageCurrentIndex,
    setThumbnailSrc,
    setPageCurrentData,
    setIsMatchedPageCurrent,
    completeVerification,
    setCountPage,
    setPageTableName,
} = actionInfoSlice.actions;

export default actionInfoSlice.reducer;
