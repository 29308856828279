import {BaseFileInfo, FileInfo} from "../common/define";
import Http from "./http.api";
import SystemConstant from "./SystemConstant";
import {map} from "rxjs/operators";

export default class ConversionAPI {
	private static host = `${SystemConstant.protocol}://${SystemConstant.Conversion.host}`;
	public static getFileInfo(file: BaseFileInfo) {
		return Http.post(`${
			ConversionAPI.host
		}/${
			SystemConstant.Conversion.API.CACHE.GetFile
		}`, file).pipe(map((re: any) => re as FileInfo));
	}
}
