import React from "react";
import { Provider } from "react-redux";
import store from "redux/store";
import MainRouter from "router";
import Theme from "theme/Theme";
import "./App.scss";
import "./theme/theme.less";

function App() {
    return (
       <Provider store={store}>
           <Theme>
               <MainRouter />
           </Theme>
       </Provider>
    );
}

export default App;

