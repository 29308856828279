import i18n from "i18next";
import common_jp from "../translations/jp/common.json";
import common_en from "../translations/en/common.json";
import common_vi from "../translations/vi/common.json";

export var currentLanguage = () => {
  const language = localStorage.getItem("LANGUAGE");
  if (language) {
    return language
  }else{
    localStorage.setItem("LANGUAGE", "en");
    return "en";
  } 
};

export const initLanguage = () => {
  let nameLanguage = currentLanguage();
  i18n.init({
    interpolation: { escapeValue: false }, // React already does escaping
    lng: nameLanguage, // language to use
    resources: {
      en: {
        common: common_en, // 'common' is our custom namespace
      },
      vi: {
        common: common_vi, // 'common' is our custom namespace
      },
      jp: {
        common: common_jp,
      }
    },
  });
}

export const setLanguage = (lg:string) =>{    
    i18n.changeLanguage(lg);
    localStorage.LANGUAGE = lg;
    return lg;
}

