export enum FoxitState {
    hand = 'hand', // STATE_HANDLER_HAND
    createFreeTextBox = 'createFreeTextBox', // STATE_HANDLER_CREATE_TEXTBOX
    createFreeTextCallout = 'createFreeTextCallout', // STATE_HANDLER_CREATE_FREE_TEXT_CALLOUT
    createCaret = 'createCaret', // STATE_HANDLER_CREATE_CARET
    createArrow = 'createArrow', // STATE_HANDLER_CREATE_ARROW
    createAreaHighlight = 'createAreaHighlight', // STATE_HANDLER_CREATE_CREATE_AREA_HIGHLIGHT
    createCircle = 'createCircle', // STATE_HANDLER_CREATE_CIRCLE
    createFileAttachment = 'createFileAttachment', // STATE_HANDLER_CREATE_FILE_ATTACHMENT
    createHighlight = 'createHighlight', // STATE_HANDLER_CREATE_HIGHLIGHT
    createImage = 'createImage', // STATE_HANDLER_CREATE_IMAGE
    createEditImage = 'createEditImage', // STATE_HANDLER_CREATE_EDIT_IMAGE
    createLink = 'createLink', // STATE_HANDLER_CREATE_LINK
    createLine = 'createLine', // STATE_HANDLER_CREATE_LINE
    createPencil = 'createPencil', // STATE_HANDLER_CREATE_PENCIL
    createPolygonCloud = 'createPolygonCloud', // STATE_HANDLER_CREATE_POLYGON_CLOUD
    createPolygon = 'createPolygon', // STATE_HANDLER_CREATE_POLYGON
    createPolyline = 'createPolyline', // STATE_HANDLER_CREATE_POLYLINE
    createReplace = 'createReplace', // STATE_HANDLER_CREATE_REPLACE
    createSquare = 'createSquare', // STATE_HANDLER_CREATE_SQUARE
    createArc = 'createArc', // STATE_HANDLER_CREATE_ARC
    createSquiggly = 'createSquiggly', // STATE_HANDLER_CREATE_SQUIGGLY
    createStamp = 'createStamp', // STATE_HANDLER_CREATE_STAMP
    createStrikeOut = 'createStrikeOut', // STATE_HANDLER_CREATE_STRIKE_OUT
    createText = 'createText', // STATE_HANDLER_CREATE_TEXT
    createUnderline = 'createUnderline', // STATE_HANDLER_CREATE_UNDERLINE
    marquee = 'marquee', // STATE_HANDLER_MARQUEE
    eraser = 'eraser', // STATE_HANDLER_ERASER
    loupe = 'loupe', // STATE_HANDLER_LOUPE
    selectTextAnnotation = 'select-text-annotation' // STATE_HANDLER_SELECT_TEXT_ANNOTATION
}

export type FoxitEvents = 
    'delete-annot' |
    'wheel-request' |
    'render-page-success' |
    'double-tap-page' |
    'create-complex-shape' |
    'on-undo-redo-stack-change' |
    'clear-stack-request' |
    'select-text' |
    'undo-request' |
    'redo-request' |
    'right-click-page' |
    'on-state-change' |
    'right-click-annotation' |
    'navigate-request' |
    'tap-page' |
    'on-undo-stack-push' |
    'zoom-request' |
    'zoom-in-request' |
    'zoom-out-request' |
    'zoom-to-success' |
    'double-tap-annotation' |
    'update-action-annotation' |
    'tap-annotation' |
    'annotation-updated' |
    'annotation-id-updated' |
    'active-annotation' |
    'unactive-annotation' |
    'annotation-appearance-updated' |
    'annotation-reply-add' |
    'annotation-review-state-annot-add' |
    'annotation-marked-state-annot-add' |
    'annotation-states-cleared' |
    'annotation-removed' |
    'annotation-moved-position' |
    'annotation-add' |
    'add-annot-success'|
    'annotation-imported' |
    'action-updated' |
    'action-add' |
    'action-remove' |
    'layer-visible-change' |
    'bookmark-added' |
    'bookmark-updated' |
    'bookmark-removed' |
    'state-annot-name-updated' |
    'page-info-change' |
    'page-rotation-change' |
    'image-added' |
    'watermark-added' |
    'graphics-updated' |
    'permission-changed' |
    'doc-password-changed' |
    'drm-encrypt-success' |
    'drm-encrypt-failed' |
    'remove-password-and-permission' |
    'page-moved' |
    'page-removed' |
    'page-added' |
    'page-number-change' |
    'render-file-success' |
    'import-pages' |
    'import-pages' |
    'apply-redaction' |
    'remove-review-state' |
    'form-value-changed' |
    'meta-data-changed' |
    'doc-modified' |
    'flattened' |
    'headerFooterUpdated' |
    'headerFooterAdded';

export interface DefaultAnnotAttribute {
    borderInfo: {
        width: number
    };
    color: number
}